import React from "react"
import classnames from "classnames"
import ProfileImage from "./ProfileImage"

export default function TestimonialPopup({ imageUrl, children, kyle }) {
  const wrapperClasses = classnames(
    "shadow-md",
    "rounded-lg",
    "flex",
    "text-sm",
    "items-center",
    "p-2",
    "max-w-md",
    {
      "bg-gray-400": kyle,
      "text-gray-900": kyle,
      "bg-gray-100": !kyle,
      "text-gray-700": !kyle,
    }
  )

  return (
    <div className={wrapperClasses}>
      <div className="mr-4">
        <ProfileImage imageUrl={imageUrl} />
      </div>
      <div>{children}</div>
    </div>
  )
}
