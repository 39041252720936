import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { useLocation } from "@reach/router"

export default function BuyButton({ primary, purchaseUrl, children }) {
  const [affiliateCode, setAffiliateCode] = useState()
  const [couponCode, setCouponCode] = useState()
  const bgColor = primary ? "bg-blue-500" : "bg-gray-400"
  const bgHoverColor = primary ? "hover:bg-blue-600" : "hover:bg-gray-500"
  const textColor = primary ? "text-white" : "text-gray-800"
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setAffiliateCode(params.get("aff"))
    setCouponCode(params.get("coupon"))
  }, [location])

  let link = purchaseUrl
  if (affiliateCode) link = `${purchaseUrl}/${affiliateCode}`
  link = `${link}/buy`
  if (couponCode) link = `${link}?coupon=${couponCode}`

  function handleClick() {
    window.plausible("Start Checkout", { props: { url: purchaseUrl } })
  }

  return (
    <a
      href={link}
      onClick={handleClick}
      className={classnames(
        "block",
        "text-center",
        "px-6",
        "py-4",
        "rounded",
        "font-bold",
        bgColor,
        bgHoverColor,
        textColor
      )}
    >
      {children}
    </a>
  )
}
