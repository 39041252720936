import React from "react"
import TextContainer from "../TextContainer"
import TextContainerHeader from "../TextContainer/Header"
import TextContainerSubHeader from "../TextContainer/SubHeader"
import TextContainerSection from "../TextContainer/Section"
import TextSectionLink from "../TextContainer/Link"

export default function IncludedSection() {
  return (
    <TextContainer className="mb-12">
      <TextContainerHeader>
        <div className="text-center text-5xl">What's Included?</div>
      </TextContainerHeader>
      <TextContainerSubHeader>
        <div className="text-center md:mx-6 mb-12 -mt-2">
          This is not your typical JavaScript course. This is everything you
          need to become a JavaScript developer.
        </div>
      </TextContainerSubHeader>
      <TextContainerSection>
        Originally when I was creating this course I was planning to just
        explain all the complex topics of JavaScript. As I was building the
        course, though, I decided to also explain how to connect all these
        JavaScript concepts{" "}
        <span className="font-bold text-black">
          in a way no other course has before.
        </span>
      </TextContainerSection>
      <TextContainerSection>
        This has resulted in a massive amount of content with the sole purpose
        of making you into the best JavaScript developer possible.
      </TextContainerSection>

      <TextContainerHeader id="beginner-course">
        Beginner Course
      </TextContainerHeader>
      <TextContainerSection>
        A complete video course with over{" "}
        <span className="font-bold text-black">13 hours</span> of content spread
        across <span className="font-bold text-black">74 videos</span> and{" "}
        <span className="font-bold text-black">10 modules</span>.
      </TextContainerSection>
      <TextContainerSection>
        This course covers every single important JavaScript concept that you
        need to understand in order to become a JavaScript developer and it
        shows you how to connect them.
      </TextContainerSection>
      <TextContainerSection>
        This can take you from literally no programming experience all the way
        to a proficient JavaScript developer. Even if you already have
        JavaScript knowledge, this course is perfect for helping you connect all
        the pieces together.
      </TextContainerSection>
      <TextContainerSection>
        While 13 hours may not seem like a lot if you are used to long Udemy
        courses, I can assure you this course is packed with information. Each
        minute is as condensed as possible with knowledge, projects, and
        exercises which means not a single second of your time with the course
        is wasted.
      </TextContainerSection>
      <TextContainerSection>
        Also, if you plan to get through this course in only 13 hours, good
        luck. With all the projects and exercises in this course it will easily{" "}
        <span className="font-bold text-black">take you over a month</span> to
        get through everything, and that is if you work on the course every
        single day!
      </TextContainerSection>
      <TextSectionLink local big href="/beginner-table-of-contents">
        View Video List
      </TextSectionLink>

      <TextContainerHeader id="advanced-course">
        Advanced Course
      </TextContainerHeader>
      <TextContainerSection>
        Once you become a proficient JavaScript developer then it is time to
        start diving into the advanced JavaScript ecosystem.
      </TextContainerSection>
      <TextContainerSection>
        This course will take you from junior to mid-level developer with just
        under <span className="font-bold text-black">14 hours</span> of content
        spread across <span className="font-bold text-black">65 videos</span>{" "}
        and <span className="font-bold text-black">9 modules</span>.
      </TextContainerSection>
      <TextContainerSection>
        This course covers not only{" "}
        <span className="font-bold text-black">
          advanced JavaScript features
        </span>
        , but more importantly covers concepts like{" "}
        <span className="font-bold text-black">security</span>,{" "}
        <span className="font-bold text-black">testing</span>, and{" "}
        <span className="font-bold text-black">how to write clean code</span>.
      </TextContainerSection>
      <TextContainerSection>
        The skills in this course will{" "}
        <span className="font-bold text-black">
          set you apart from all other job applicants
        </span>{" "}
        as most developers never learn any of these concepts until after landing
        their first job. I personally didn't even know what 90% of the concepts
        in this course were when I landed my first job and was forced to learn
        them on the job.
      </TextContainerSection>
      <TextContainerSection>
        I can only imagine how much{" "}
        <span className="font-bold text-black">easier</span> it would have been{" "}
        <span className="font-bold text-black">to land my first job</span> if I
        knew these skills before applying.
      </TextContainerSection>
      <TextSectionLink local big href="/advanced-table-of-contents">
        View Video List
      </TextSectionLink>

      <TextContainerHeader id="bonus-projects">
        Multiple Projects
      </TextContainerHeader>
      <TextContainerSection>
        Learning how to connect JavaScript concepts is the only way to become a
        JavaScript developer and one of the best ways to do that is through
        projects.
      </TextContainerSection>
      <TextContainerSection>
        JavaScript Simplified includes tons of projects of various difficulty
        and scale which are perfectly crafted to help you slowly build up your
        JavaScript skills{" "}
        <span className="text-black font-bold">without overwhelming you.</span>
      </TextContainerSection>
      <TextContainerSection>
        There are never more than a few concepts introduced at a time in between
        projects. This ensures you are able to fully comprehend each concept and
        how to connect it to other concepts.
      </TextContainerSection>
      <TextContainerSection>
        This course also includes{" "}
        <span className="text-black font-bold">6 large bonus projects</span>{" "}
        that combine together all the concepts in this course. These projects
        are perfect for practicing your JavaScript skills and are also great
        additions to your resume. Best of all, these projects are crafted with
        specific ways you can build upon them which will help further deepen
        your project building skills.
      </TextContainerSection>
      <TextSectionLink local big href="/bonus-projects-table-of-contents">
        View Bonus Projects List
      </TextSectionLink>

      <TextContainerHeader>Countless Exercises</TextContainerHeader>
      <TextContainerSection>
        This is where{" "}
        <span className="text-black font-bold">
          JavaScript Simplified really shines.
        </span>
      </TextContainerSection>
      <TextContainerSection>
        In order to learn JavaScript you must practice a lot! That is why in
        JavaScript Simplified there are tons of exercises throughout nearly
        every video in the course.
      </TextContainerSection>
      <TextContainerSection>
        Not a single concept is introduced without specific exercises also being
        introduced with it. These exercises vary drastically in scale, from
        simple commands to full projects, but they all serve the same purpose.{" "}
        <span className="text-black font-bold">
          Teaching you how to connect JavaScript concepts.
        </span>
      </TextContainerSection>
      <TextContainerSection>
        These exercises are what separate JavaScript Simplified from other
        JavaScript courses and ensures that you learn not only JavaScript, but
        also{" "}
        <span className="text-black font-bold">
          how to think like a developer
        </span>
        .
      </TextContainerSection>

      <TextContainerHeader>Helpful Community</TextContainerHeader>
      <TextContainerSection>
        When you get stuck, sometimes the best help is simply another person.
        This is why I have a{" "}
        <span className="font-bold text-black">private Discord community</span>{" "}
        specifically for the members of JavaScript Simplified.
      </TextContainerSection>
      <TextContainerSection>
        In this community you can ask other course members as well as me any of
        your JavaScript questions, and since the community is fairly active you
        will almost always get a quick response.
      </TextContainerSection>
      <TextContainerSection>
        Nothing beats one-on-one communication with another person.
      </TextContainerSection>

      <TextContainerHeader id="interviews">
        Tips For Landing A Job
      </TextContainerHeader>
      <TextContainerSection>
        Landing your first job as a web developer is{" "}
        <span className="font-bold text-black">really hard</span> and being
        self-taught doesn't make it any easier. That is why I interviewed
        incredible developers like{" "}
        <TextSectionLink href="https://twitter.com/DThompsonDev">
          Danny Thompson
        </TextSectionLink>
        , and{" "}
        <TextSectionLink href="https://twitter.com/eddiejaoude">
          Eddie Jaoude
        </TextSectionLink>{" "}
        to figure out how they landed their first developer jobs.
      </TextContainerSection>
      <TextContainerSection>
        These talks cover so many different tips and tricks that you can use to
        get yourself into{" "}
        <span className="font-bold text-black">more interviews</span> and
        successfully ace those interviews. The topics we cover will truly{" "}
        <span className="font-bold text-black">
          change the way you look at applying for jobs
        </span>
        .
      </TextContainerSection>
      <TextSectionLink local big href="/interview-table-of-contents">
        View Interview List
      </TextSectionLink>

      <TextContainerHeader id="interviews">
        Free Quokka.js Pro For 3 Months
      </TextContainerHeader>
      <TextContainerSection>
        One of the{" "}
        <span className="font-bold text-black">
          hardest parts of learning JavaScript
        </span>{" "}
        is understanding how the code works and seeing any mistakes you make.
        Text editors are great at helping you with this, but they are{" "}
        <span className="font-bold text-black">not ideal</span>. This is where
        Quokka.js comes in.
      </TextContainerSection>
      <TextContainerSection>
        Quokka.js is a developer{" "}
        <span className="font-bold text-black">
          productivity tool specifically built for JavaScript
        </span>{" "}
        that shows you exactly what is happening in the code at each line. It
        also has robust error reporting that makes learning JavaScript{" "}
        <span className="font-bold text-black">quicker and easier</span>.
      </TextContainerSection>
      <TextSectionLink href="https://quokkajs.com">
        Learn More About Quokka.js
      </TextSectionLink>
    </TextContainer>
  )
}
