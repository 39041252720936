import React from "react"
import classnames from "classnames"

export default function ProfileImage({ imageUrl, large, xl }) {
  const size = xl ? "w-48 h-48" : large ? "w-20 h-20" : "w-8 h-8"
  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
        boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.2)",
      }}
      className={classnames(
        "rounded-full",
        "overflow-hidden",
        "bg-cover",
        size,
        "flex-shrink-0"
      )}
    />
  )
}
