import React from "react"
import InlineQuote from "./InlineQuote"
import ProfileImage from "./ProfileImage"

export default function TestimonialSection({ children, imageUrl, name }) {
  return (
    <div className="bg-gray-200">
      <div className="max-w-screen-lg mx-auto py-20 px-8 flex flex-col content-center items-center">
        <div className="mb-6">
          <ProfileImage large imageUrl={imageUrl} />
        </div>
        <InlineQuote
          quoteSize="h-6 w-6 md:h-8 md:w-8"
          textSize="text-xl md:text-3xl"
          spacing="mx-3 md:mx-6"
        >
          {children}
        </InlineQuote>
        <div className="text-lg text-blue-700 font-bold mt-6">{name}</div>
      </div>
    </div>
  )
}
