import React from "react"
import TextContainerSection from "../TextContainer/Section"
import TextContainer from "../TextContainer"
import TextSectionLink from "../TextContainer/Link"
import TextContainerHeader from "../TextContainer/Header"
import TextContainerQuote from "../TextContainer/Quote"
import TestimonialSection from "../TestimonialSection"

export default function ProjectTextSection() {
  return (
    <>
      <TextContainer>
        <TextContainerSection>
          Hey! I'm Kyle Cook. You may know me as{" "}
          <TextSectionLink href="https://www.youtube.com/WebDevSimplified">
            Web Dev Simplified
          </TextSectionLink>
          , but not too long ago I was the person saying everything above. I
          watched hundreds, maybe even thousands, of JavaScript tutorials, but I
          still struggled to make even the most basic projects on my own. Just
          looking at a blank text editor filled me with fear.
        </TextContainerSection>
        <TextContainerSection>
          I just assumed that programming wasn't for me. It seemed like everyone
          else knew exactly what to do while I was stuck Googling even the most
          basic problems. It wasn't until much later that I realized the problem
          wasn't me, but instead was how I was trying to learn.
        </TextContainerSection>
        <TextContainerSection>
          <span className="font-bold text-black">
            I realized that the tutorials and courses I was watching weren't
            teaching me the concepts I needed in order to become a JavaScript
            developer.
          </span>{" "}
          On top of that, the way I was practicing JavaScript was terrible and
          caused me more harm than good.
        </TextContainerSection>
        <TextContainerSection>
          Luckily, I stumbled upon the perfect learning technique that allowed
          me to learn JavaScript and build any project I could dream of.
        </TextContainerSection>

        <TextContainerHeader>
          Learn how to build any project, not just the ones in this course
        </TextContainerHeader>
        <TextContainerSection>
          By far the biggest thing that helped me learn JavaScript was realizing
          that learning JavaScript is just like learning a foreign language.
        </TextContainerSection>
        <TextContainerSection>
          There are two parts to learning a foreign language. The words of the
          language and the grammar of the language. Without a strong
          understanding of both the words and the grammar of a language you are
          unable to express yourself in that language. Even if you knew every
          word in a foreign language it would be useless unless you also
          understood how to use grammar to connect those words.
        </TextContainerSection>
        <TextContainerSection>
          This is obvious, but what most people don't realize is this applies to
          programming languages as well.
        </TextContainerSection>
        <TextContainerQuote>
          Learning JavaScript is no different than learning a foreign language.
        </TextContainerQuote>
        <TextContainerSection>
          In JavaScript there are two parts as well. The syntax/concepts and the
          ability to connect those concepts together. The syntax and individual
          concepts of JavaScript are like the words of a foreign language. It
          doesn't matter how many JavaScript concepts you know, if you can't
          connect them you can't create JavaScript programs. This connection of
          concepts is like the grammar of a foreign language.
        </TextContainerSection>
        <TextContainerSection>
          Once I realized this I immediately saw why I was struggling so much. I
          spent a ton of time reading documentation and learning JavaScript
          concepts, but I never practiced connecting them properly. This meant I
          knew all the concepts of JavaScript, but had no way to connect them
          and thus I could not create JavaScript programs.
        </TextContainerSection>
        <TextContainerSection>
          I needed to change the way I used projects to learn.
        </TextContainerSection>
      </TextContainer>

      <div className="my-12">
        <TestimonialSection
          imageUrl="codestackr.jpg"
          name="Jesse Hall (codeSTACKr)"
        >
          Learning JavaScript can be daunting. Fortunately, Kyle is an amazing
          teacher. Not only will you learn JavaScript, but also how to think
          like a developer.
        </TestimonialSection>
      </div>

      <TextContainer className="mb-12">
        <TextContainerHeader>Most courses get this wrong</TextContainerHeader>
        <TextContainerSection>
          Have you ever noticed how when you ask someone how to learn JavaScript
          they always tell you to build projects, but no matter how much you try
          to build a project you always fail. This really discouraged me when I
          was learning since no matter how many times I tried to create a
          project I always got stuck.
        </TextContainerSection>
        <TextContainerQuote>
          If projects are the best way to learn then why don't they work for me?
        </TextContainerQuote>
        <TextContainerSection>
          The reason for this is because I was using projects the wrong way, and
          unfortunately nearly every JavaScript course also uses projects the
          wrong way.
        </TextContainerSection>
        <TextContainerSection>
          Projects in other JavaScript courses follow one of two patterns. They
          either are a complete tutorial where the teacher tells you how to
          build the entire project, or they are a project assigned to the
          student to complete with no guidance or direction. Both of these
          techniques are terrible for learning.
        </TextContainerSection>
        <TextContainerSection>
          The first example of a teacher-led tutorial is the equivalent of a
          teacher writing out a paper for you and you just copying down the
          paper word for word expecting to learn how the grammar works. This is
          a pretty ridiculous way to learn a foreign language and is also
          equally ridiculous for learning JavaScript.
        </TextContainerSection>
        <TextContainerSection>
          Teacher-led tutorials must be used sparingly to explain the connection
          of smaller concepts before diving into a fully-fledged project.
        </TextContainerSection>
        <TextContainerSection>
          The second example of a student building a project from scratch is
          obviously problematic since learning how to do that is the reason you
          are in the course to begin with. This would be like joining a foreign
          language class and on the first day the teacher tells you to write an
          entire book in that foreign language. This will never work.
        </TextContainerSection>
        <TextContainerSection>
          Instead, these student projects need to be crafted in such a way that
          concepts are slowly combined together one at a time.
        </TextContainerSection>
        <TextContainerSection>
          This is where JavaScript Simplified is different. This course teaches
          you concepts one at a time. Then I show you how to combine a small
          number of concepts before giving you exercises focused around
          combining those few concepts together. This is repeated until you are
          able to build larger and larger projects with more complex concepts
          completely on your own.
        </TextContainerSection>
        <TextContainerSection>
          This is how learning a foreign language works. You first learn a few
          easy words. Then you combine those words together into pairs.
          Eventually, you will start forming sentences with those words and
          those sentences will soon become paragraphs, pages, and finally a
          complete book.
        </TextContainerSection>
      </TextContainer>
    </>
  )
}
