import React from "react"
import Card from "./Card"
import CardItem from "./CardItem"

export default function PricingCards() {
  return (
    <div className="flex justify-center items-center flex-col lg:flex-row">
      <div className="w-full lg:w-1/3">
        <Card
          className="lg:rounded-r-none bg-gray-200 mb-8 lg:mb-0"
          title="Basic Package"
          originalPrice={149}
          discountedPrice={127}
          purchaseUrl="https://courses.webdevsimplified.com/javascript-simplified-beginner"
        >
          <CardItem>
            Entire{" "}
            <span className="font-bold">JavaScript Simplified - Beginner</span>{" "}
            course
          </CardItem>
          <CardItem>
            Over <span className="font-bold">13 hours</span> of content across{" "}
            <span className="font-bold">74 videos</span>
          </CardItem>
          <CardItem>Before and after source code for all projects</CardItem>
          <CardItem>Exclusive access to private Discord community</CardItem>
          <CardItem>All future updates</CardItem>
          <CardItem>3 months of Quokka.js Pro</CardItem>
          <CardItem>Lifetime access</CardItem>
        </Card>
      </div>
      <div className="w-full lg:w-1/3">
        <Card
          className="lg:rounded-r-none bg-gray-100 mb-8 lg:mb-0"
          badgeText="Most Popular"
          primary
          title="Complete Package"
          originalPrice={299}
          discountedPrice={197}
          purchaseUrl="https://courses.webdevsimplified.com/javascript-simplified-complete-package"
        >
          <CardItem>
            <span className="font-bold">Everything in the Basic Package</span>
          </CardItem>
          <CardItem>
            Entire{" "}
            <span className="font-bold">JavaScript Simplified - Advanced</span>{" "}
            course
          </CardItem>
          <CardItem>
            Over <span className="font-bold">27 hours</span> of content across{" "}
            <span className="font-bold">140 videos</span>
          </CardItem>
          <CardItem>
            Download all videos for{" "}
            <span className="font-bold">offline access</span>
          </CardItem>
          <CardItem>Before and after source code for all projects</CardItem>
          <CardItem>Exclusive access to private Discord community</CardItem>
          <CardItem>All future updates</CardItem>
          <CardItem>3 months of Quokka.js Pro</CardItem>
          <CardItem>Lifetime access</CardItem>
        </Card>
      </div>
      <div className="w-full lg:w-1/3">
        <Card
          badgeText="Best Value"
          primary
          title="Premium Package"
          originalPrice={399}
          discountedPrice={297}
          purchaseUrl="https://courses.webdevsimplified.com/javascript-simplified-premium-bundle"
        >
          <CardItem>
            <span className="font-bold">
              Everything in the Complete Package
            </span>
          </CardItem>
          <CardItem>
            Just under <span className="font-bold">40 hours</span> of content
            across <span className="font-bold">150 videos</span>
          </CardItem>
          <CardItem>
            <span className="font-bold">Six large bonus projects</span>
          </CardItem>
          <CardItem>
            Four interviews with successful self-taught developers on{" "}
            <span className="font-bold">how to land your first job</span>
          </CardItem>
          <CardItem>
            Download all videos for{" "}
            <span className="font-bold">offline access</span>
          </CardItem>
          <CardItem>Before and after source code for all projects</CardItem>
          <CardItem>Exclusive access to private Discord community</CardItem>
          <CardItem>All future updates</CardItem>
          <CardItem>3 months of Quokka.js Pro</CardItem>
          <CardItem>Lifetime access</CardItem>
        </Card>
      </div>
    </div>
  )
}
