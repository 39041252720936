import React from "react"
import Typewriter from "typewriter-effect"

export default function TextEditor() {
  return (
    <div className="bg-gray-800 shadow rounded-lg font-mono text-lg flex flex-col">
      <div className="flex px-3 py-2 bg-gray-900 rounded-t-lg">
        <div className="w-3 h-3 mr-2 bg-red-500 rounded-full"></div>
        <div className="w-3 h-3 mr-2 bg-yellow-500 rounded-full"></div>
        <div className="w-3 h-3 bg-green-500 rounded-full"></div>
      </div>
      <div className="flex w-full">
        <div className="flex flex-col pl-3 pr-2 py-1 border-r-2 border-gray-700 text-gray-400">
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div className="block sm:hidden">7</div>
          <div className="block sm:hidden">8</div>
          <div className="block sm:hidden">9</div>
        </div>
        <div>
          <div className="flex flex-col pl-2 pr-3 py-1 text-gray-100 whitespace-pre-wrap">
            <Typewriter
              options={{ loop: true }}
              onInit={initializeTypewriter}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function initializeTypewriter(typewriter) {
  typewriter
    .typeString("Where do I start?")
    .pauseFor(1000)
    .typeString("\n\nfunctio")
    .deleteChars(7)
    .typeString("const temp")
    .pauseFor(150)
    .deleteAll(0.001)
    .typeString("Why is this so hard?")
    .pauseFor(1000)
    .typeString("\n\nDoes everyone struggle this much?")
    .pauseFor(1000)
    .typeString("\n\nThere has to be an easier way")
    .pauseFor(1000)
    .deleteAll(0.001)
    .start()
}
