import React from "react"
import Container from "../Container"
import Question from "./Question"
import TextSectionLink from "../TextContainer/Link"

export default function FAQSection() {
  return (
    <div className="bg-blue-900 text-blue-100">
      <Container>
        <h2 className="font-bold text-4xl text-center font-display mb-12">
          Frequently Asked Questions
        </h2>
        <div>
          <Question questionText="What do I need to know to take this course?">
            Nothing! You really don't need to know anything about programming
            before taking this course. The only thing you should know is the
            basics of HTML since all of the projects in this course will be
            based on HTML.
          </Question>
          <Question questionText="How do I access the course after purchasing it?">
            Check your email after purchasing the course. You should receive an
            email with a link to the course. If you do not receive this email
            please send us a message at{" "}
            <TextSectionLink
              light
              notBlank
              href="mailto:support@webdevsimplified.com"
            >
              support@webdevsimplified.com
            </TextSectionLink>{" "}
            and we will help you work it out.
          </Question>
          <Question questionText="Does this course cover React, Express, jQuery, etc?">
            No. This course is focused 100% on learning JavaScript. This means
            that no major frameworks or libraries will be used. Smaller
            libraries will be used for specific projects and things like Parcel,
            and Jest will be used to explain testing and bundlers, but the focus
            of this course is solely on JavaScript. If you are interested in
            learning React check out{" "}
            <TextSectionLink
              href="https://courses.webdevsimplified.com/learn-react-today"
              light
            >
              my complete React course
            </TextSectionLink>
            .
          </Question>
          <Question questionText="When was this course recorded?">
            This course was recorded in March of 2021. That means this course
            focuses heavily on modern JavaScript concepts such as promises,
            modules, and bundlers without wasting your time on outdated
            JavaScript concepts.
          </Question>
          <Question questionText="What if I buy the course and do not like it?">
            While I am sure that you will love this course, if for any reason
            you do not, just email us at{" "}
            <TextSectionLink
              light
              notBlank
              href="mailto:support@webdevsimplified.com"
            >
              support@webdevsimplified.com
            </TextSectionLink>{" "}
            within 6 months for a full refund.
          </Question>
          <Question questionText="Can I upgrade my bundle later?">
            Yes! If you would like to update your bundle later just send us an
            email at{" "}
            <TextSectionLink
              light
              notBlank
              href="mailto:support@webdevsimplified.com"
            >
              support@webdevsimplified.com
            </TextSectionLink>
            .
          </Question>
          <Question questionText="Can I buy a team bundle?">
            Of course! Just send us an email at{" "}
            <TextSectionLink
              light
              notBlank
              href="mailto:support@webdevsimplified.com"
            >
              support@webdevsimplified.com
            </TextSectionLink>{" "}
            and we can workout a bundle for your entire team.
          </Question>
          <Question questionText="Are there student discounts available?">
            Yes! If you are a student, email us at{" "}
            <TextSectionLink
              light
              notBlank
              href="mailto:support@webdevsimplified.com"
            >
              support@webdevsimplified.com
            </TextSectionLink>{" "}
            using your student email and we will send over a discount code.
          </Question>
          <Question questionText="Are there PPP discounts available?">
            Yes! If you are from a country that we offer PPP discounts for, you should see a discount banner at the top of this page. If you do not see this banner and you believe your country qualifies, make sure you turn off any VPNs and refresh the page.
          </Question>
          <Question questionText="Any other questions?">
            If you have any more questions about the course email us at{" "}
            <TextSectionLink
              light
              notBlank
              href="mailto:support@webdevsimplified.com"
            >
              support@webdevsimplified.com
            </TextSectionLink>{" "}
            and we will answer any questions you have.
          </Question>
        </div>
      </Container>
    </div>
  )
}
