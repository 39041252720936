import React from "react"
import Head from "../components/Head"
import Header from "../components/Header"
import TextEditorSection from "../components/TextEditorSection"
import ProjectTextSection from "../components/ProjectTextSection"
import TestimonialSection from "../components/TestimonialSection"
import PricingSection from "../components/PricingSection"
import IncludedSection from "../components/IncludedSection"
import FAQSection from "../components/FAQSection"
import AboutMeSection from "../components/AboutMeSection"

export default function Home() {
  return (
    <div id="parity-deals-container" className="max-h-screen overflow-y-auto">
      <Head />
      <Header />
      <TextEditorSection />
      <ProjectTextSection />
      <TestimonialSection name="Brent Morton" imageUrl="brent.jpg">
        JavaScript Simplified is concise, to the point, and full of fun projects
        which constantly had me putting together what I had learned in
        challenging ways.
      </TestimonialSection>
      <IncludedSection />
      <TestimonialSection name="Caleb Curry" imageUrl="caleb.jpg">
        Kyle's content goes beyond the average in both quality and depth. With
        his dedication to excellence, there's no better option when it comes to
        learning web development.
      </TestimonialSection>
      <PricingSection />
      <TestimonialSection name="Claudio Bernasconi" imageUrl="claudio.jpg">
        Although I have a fundamental understanding of JavaScript, I learned a
        lot from the advanced sections of this course. Honestly, it's the most
        comprehensive JavaScript course I've ever seen.
      </TestimonialSection>
      <FAQSection />
      <AboutMeSection />
    </div>
  )
}
