import React from "react"
import InlineQuote from "../InlineQuote"

export default function Quote({ children }) {
  return (
    <InlineQuote className="my-12 max-w-screen-sm mx-auto">
      {children}
    </InlineQuote>
  )
}
