import React from "react"
import CheckIcon from "../../icons/CheckIcon"

export default function CardItem({ children }) {
  return (
    <div className="flex mb-3">
      <div style={{ marginTop: "1px" }}>
        <CheckIcon />
      </div>
      <div className="ml-2">{children}</div>
    </div>
  )
}
