import React from "react"
import Container from "../Container"
import PricingCards from "./PricingCards"
// import TimerHeader from "./TimerHeader"
// import CountdownTimer from "react-countdown"
// import classnames from "classnames"

// const CART_CLOSE_DATE = new Date("2021/04/12 23:59:59 GMT-0500")
// const CART_CLOSE_DATE = new Date("9999/11/11 23:59:59 GMT-0600") // In future
// const CART_CLOSE_DATE = new Date("2000/11/11 23:59:59 GMT-0600") // In past

export default function PricingSection() {
  return (
    <div className="bg-blue-700" id="pricing">
      <Container>
        {/* <CountdownTimer
          date={CART_CLOSE_DATE}
          renderer={({ completed, ...props }) => (
            <>
              <h2
                className={classnames(
                  "text-center",
                  "text-white",
                  "font-bold",
                  "text-4xl",
                  "sm:text-5xl",
                  "font-display",
                  { "mb-4": !completed, "mb-8": completed }
                )}
              >
                Start Learning JavaScript Now
              </h2>
              {!completed && <TimerHeader {...props} />}
            </>
          )}
        /> */}
        <h2 className="text-center text-white font-bold text-4xl sm:text-5xl font-display mb-8">
          Start Learning JavaScript Now
        </h2>
        <PricingCards />
      </Container>
    </div>
  )
}
