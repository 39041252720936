import React from "react"
import Container from "../Container"
import TextContainerHeader from "../TextContainer/Header"
import TextContainer from "../TextContainer"
import TextContainerSection from "../TextContainer/Section"
import TextSectionLink from "../TextContainer/Link"

export default function AboutMeSection() {
  return (
    <div className="bg-gray-200">
      <Container>
        <TextContainer>
          <TextContainerHeader>
            <div className="text-center text-4xl md:text-5xl -mt-12">
              Hi! My name is <span className="text-blue-500">Kyle Cook</span>
            </div>
          </TextContainerHeader>
        </TextContainer>
        <div className="flex flex-col md:flex-row items-center">
          <div
            style={{
              backgroundImage: "url(kyle.jpg)",
              boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.2)",
            }}
            className="rounded-lg overflow-hidden bg-cover h-48 w-48 lg:w-64 lg:h-64 flex-shrink-0 mt-8 md:mt-0 md:mr-8 order-2 md:order-none"
          />
          <div className="flex flex-col items-center -mb-6">
            <TextContainerSection>
              I have been a full stack web developer since 2015 and I love
              JavaScript, but it wasn't always like that. I went through a
              four-year Computer Engineering program and learned nothing about
              web development, so I spent countless nights studying on my own
              and building fun projects in JavaScript. Eventually I was able to
              learn enough JavaScript to comfortably build any project I could
              think of and now my passion is sharing that knowledge with you.
            </TextContainerSection>
            <TextContainerSection>
              Since I learned programming both on my own and in college I am
              able to leverage my formal education as well as my experience as a
              self-taught developer to create the most comprehensive and easy to
              understand courses available. I also run the YouTube channel{" "}
              <TextSectionLink href="https://www.youtube.com/WebDevSimplified">
                Web Dev Simplified
              </TextSectionLink>{" "}
              with over 1 million subscribers.
            </TextContainerSection>
          </div>
        </div>
      </Container>
    </div>
  )
}
