import React from "react"
import BuyButton from "./BuyButton"
import classnames from "classnames"

export default function Card({
  title,
  originalPrice,
  discountedPrice,
  purchaseUrl,
  children,
  primary,
  className,
  badgeText,
}) {
  return (
    <div
      className={classnames(
        "shadow-xl",
        "bg-white",
        "rounded-lg",
        "p-8",
        "pt-10",
        "relative",
        className
      )}
    >
      {badgeText && (
        <div className="bg-blue-800 text-blue-100 px-3 py-1 absolute text-sm top-0 left-0 ml-2 mt-2 rounded">
          {badgeText}
        </div>
      )}
      <div className="font-bold font-display text-gray-700 text-center mb-2 uppercase">
        {title}
      </div>
      <div className="flex items-center justify-center">
        <div className="text-gray-600 text-xl mr-4 line-through">
          ${originalPrice}
        </div>
        <div className="text-black-700 text-4xl flex items-start text-black font-bold">
          <span className="text-2xl">$</span>
          {discountedPrice}
        </div>
      </div>
      <div className="my-6 text-gray-800">{children}</div>
      <BuyButton purchaseUrl={purchaseUrl} primary={primary}>
        Buy Now
      </BuyButton>
    </div>
  )
}
