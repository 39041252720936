import React from "react"
import Container from "./Container.js"
import TestimonialPopup from "./TestimonialPopup.js"
import HeaderTitle from "./HeaderTitle.js"

export default function Header() {
  return (
    <div className="bg-gray-200">
      <Container>
        <div className="pb-12 flex justify-between relative">
          <div className="xl:w-2/3 lg:w-3/4">
            <HeaderTitle
              title="JavaScript Simplified"
              subtitle={
                <>
                  <span className="block">Stop Getting Stuck</span>
                  <span className="block">
                    Become A{" "}
                    <span className="text-blue-500 sm:text-gray-900 sm:border-b-4 sm:border-blue-500">
                      JavaScript Developer
                    </span>
                  </span>
                </>
              }
            />
            <p className="text-lg sm:text-xl text-gray-700 mt-6 mb-6 sm:mb-12 text-center sm:text-left">
              Learn how to think like a developer and build any project you can
              dream of by taking action instead of just following along with
              tutorials.
            </p>
            <div className="flex justify-center sm:justify-start gap-2">
              <a
                href="#pricing"
                className="text-white bg-blue-500 border-0 py-3 px-8 hover:bg-blue-600 rounded-lg text-2xl font-bold"
              >
                Start Now
              </a>
              <a
                href="https://courses.webdevsimplified.com/login"
                class="text-blue-500 border border-blue-500 py-2 px-4 hover:bg-blue-200 rounded-lg font-bold sm:hidden flex items-center"
              >
                Log In
              </a>
            </div>
          </div>
          <a
            href="https://courses.webdevsimplified.com/login"
            class="text-blue-500 border border-blue-500 py-2 px-4 hover:bg-blue-200 rounded-lg font-bold flex-shrink-0 absolute top-0 right-0 hidden sm:block"
          >
            Log In
          </a>
        </div>

        <div className="max-w-4xl mx-auto">
          <div className="flex justify-center w-full">
            <div className="flex flex-col items-end">
              <div className="pop-in ml-8" style={{ animationDelay: "500ms" }}>
                <TestimonialPopup imageUrl="person1.jpg">
                  JavaScript is so hard! There is too much to know.
                </TestimonialPopup>
              </div>
              <div
                className="pop-in -mt-1 mr-4"
                style={{ animationDelay: "1000ms" }}
              >
                <TestimonialPopup imageUrl="kyle.jpg" kyle>
                  There is a lot to learn, but you don't need it all. Let me
                  show you what you need to know.
                </TestimonialPopup>
              </div>
            </div>
          </div>
          <div className="flex justify-end w-full mt-4">
            <div className="flex flex-col items-end">
              <div className="ml-8 pop-in" style={{ animationDelay: "2000ms" }}>
                <TestimonialPopup imageUrl="person2.jpg">
                  When I watch tutorials it all makes sense, but when I try to
                  do it on my own I get stuck.
                </TestimonialPopup>
              </div>
              <div
                className="-mt-1 mr-4 pop-in"
                style={{ animationDelay: "2500ms" }}
              >
                <TestimonialPopup imageUrl="kyle.jpg" kyle>
                  I used to feel the same way! Let me show you how I overcame
                  this.
                </TestimonialPopup>
              </div>
            </div>
          </div>
          <div className="flex justify-start w-full mt-4">
            <div className="flex flex-col items-end">
              <div className="ml-8 pop-in" style={{ animationDelay: "3500ms" }}>
                <TestimonialPopup imageUrl="person3.jpg">
                  I have tried so many courses, but still don't know how to code
                  JavaScript.
                </TestimonialPopup>
              </div>
              <div
                className="-mt-1 mr-4 pop-in"
                style={{ animationDelay: "4000ms" }}
              >
                <TestimonialPopup imageUrl="kyle.jpg" kyle>
                  This is because other courses don't teach you the most
                  important topic, how to think like a developer.
                </TestimonialPopup>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
