import React from "react"
import Container from "../Container"
import TextEditor from "./TextEditor"
import InlineQuote from "../InlineQuote"

export default function TextEditorSection() {
  return (
    <Container>
      <div className="flex justify-between items-center flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 pt-12 md:pt-0">
          <InlineQuote>
            When I follow along with tutorials I feel I know exactly what to do,
            but as soon as I try to build my own projects I get stuck.
          </InlineQuote>
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pl-20">
          <TextEditor />
        </div>
      </div>
    </Container>
  )
}
