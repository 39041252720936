import React from "react"
import QuoteIcon from "../icons/QuoteIcon"
import classnames from "classnames"

export default function InlineQuote({
  children,
  textSize = "text-xl md:text-2xl",
  spacing = "mx-3",
  className,
  quoteSize,
}) {
  return (
    <div
      className={classnames(
        "flex",
        "items-center",
        "justify-center",
        "w-full",
        className
      )}
    >
      <QuoteIcon size={quoteSize} reverse className="self-start" />
      <blockquote
        className={classnames(
          "text-black",
          spacing,
          "italic",
          "text-center",
          textSize
        )}
      >
        {children}
      </blockquote>
      <QuoteIcon size={quoteSize} className="self-end" />
    </div>
  )
}
