import React from "react"

export default function Question({ questionText, children }) {
  return (
    <div>
      <div className="text-blue-100 text-xl font-display font-bold mb-2">
        {questionText}
      </div>
      <div className="text-blue-300 ml-0 sm:ml-4 text-lg mb-8">{children}</div>
    </div>
  )
}
