import React from "react"

export default function TextContainerHeader({ children, ...props }) {
  return (
    <h2
      {...props}
      className="text-gray-900 text-3xl pt-12 pb-6 font-bold font-display leading-tight tracking-tight"
    >
      {children}
    </h2>
  )
}
